.flightTimeline {
  position: relative;
  text-align: center;
  margin-top: -25px;
}

.line {
  width: 100%;
  position: absolute;
  top: 74px;
  background-image: linear-gradient(
    90deg,
    #aaaaaa,
    #aaaaaa 45%,
    transparent 45%,
    transparent 100%
  );
  background-size: 10px 1px;
  border: none;
  height: 1px;
  z-index: -2;
}

.selectButton {
  margin-top: 40px;
  background: #19bc9b;
  height: 42px;
  width: 100%;
  color: white;
  font: 14px/14px Futura Hv BT;
  border: 0px;
}

.selectButton:focus {
  outline: none;
}

.flightsScrollBtn {
  position: fixed;
  bottom: 20px;
  right: 250px;
}

.flightsFilter {
  z-index: 5;
  position: absolute;
  top: 80px;
  width: 397px;
  background: #f5f7f6;
  height: auto;
  box-shadow: 0px 3px 6px #00000029;
  padding: 25px;
  height: 85vh;
  overflow: auto;
}

.topFlightBtn {
  border: 0px;
  background: transparent;
  padding: 0px;
}
.flightsSearch .react-datepicker-popper {
  z-index: 8 !important;
}

.topFlightBtn:focus {
  outline: none;
}

.showMoreAirlines {
  margin-top: 10px;
  margin-bottom: -20px;
  width: 100%;
  padding: 10px;
  font: 14px/14px Futura LT BT;
  color: #7f7f7f;
  background: #dfdfdf;
  border: none;
}

.clearFlightsFilters {
  margin-top: 40px;
  margin-bottom: -20px;
  width: 100%;
  padding: 10px;
  font: 14px/14px Futura Md BT;
  color: #19bc9b;
  background: #dfdfdf;
  border: none;
}

.flightsFilter button:focus {
  outline: none;
}

.timesBtn button {
  margin-top: 0px;
  padding: 10px;
  font: 14px/14px Futura Hv BT;
  width: 50%;
}

.selectFlight {
  width: 80%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
  padding-top: 40px;
  padding-bottom: 20px;
}

.flightsLoader {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.flightExpired {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 6px #00000029;
}

.flightExpired .expiredTop {
  width: 800px;
  height: 160px;
  background: #000000;
  color: #eeeeee;
  text-align: center;
  font: 30px/36px Futura Md BT;
  letter-spacing: 0px;
}
.flightExpired .expiredTop h1 {
  width: 60%;
  margin: 0 auto;
  padding: 40px;
}
.flightExpired .expiredBottom p {
  width: 92%;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  font: 24px/30px Futura Lt BT;
  letter-spacing: 0px;
  color: #333333;
}
.flightExpired .expiredBottom {
  width: 800px;
  height: 220px;
  background: #ffffff;
}
