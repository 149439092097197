.text_right {
    text-align: right !important;
}



.content_container_280_215 {
    position: relative;
    z-index: 0;
    padding: 0;
    margin: 0;
}

.content_text_container_280_215 {
    position: absolute;
    top: 93%;
    -ms-transform: translateY(-93%);
    transform: translateY(-93%);
    left: 3%;
    right: 3%;
    bottom: 0;
    z-index: 1;
}

.content_text_container_top_plus {
    position: absolute;
    top: 25%;
    -ms-transform: translateY(-25%);
    transform: translateY(-25%);
    left: 10%;
    right: 80%;
    bottom: 0;
    z-index: 1;
}

.content_text_container_top_star {
    position: absolute;
    top: 20%;
    -ms-transform: translateY(-20%);
    transform: translateY(-20%);
    left: 90%;
    right: 3%;
    bottom: 0;
    z-index: 1;
}

.content_text_container_top_star_left_85 {
    position: absolute;
    top: 25%;
    -ms-transform: translateY(-25%);
    transform: translateY(-25%);
    left: 80%;
    right: 3%;
    bottom: 0;
    z-index: 1;
}

.margin_top_timeline_50 {
    margin-top: 50px;
}

@media screen and (min-width: 1550px) {
    .bg_green {
        background-color: var(--mainGreen);
        margin-left: 15px;
        height: 80px;
        width: 260px !important;
    }
}

@media screen and (max-width: 1550px) {

    .futura_hv_bt_18_21_black_menu {
        text-align: left;
        font: 12px/14px Futura Hv BT;
        letter-spacing: 0;
        color: var(--frameBlack);
        cursor: pointer;
    }

    .futura_md_bt_18_14_white {
        text-align: left;
        font: 12px/9px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_18_14 {
        text-align: left;
        font: 12px/18px Futura Md BT;
        letter-spacing: 0;
    }

    .destinations_days_container {
        left: 70% !important;
    }
    .destinations_days_container_day {
        left: 79% !important;
    }
    .destinations_days_container_dialog {
        left: 70% !important;
    }
    .destinations_days_container_dialog_day {
        left: 79% !important;
    }

    .bg_color_map_days_btn {
        width: 80px !important;
    }
    .bg_color_map_days_btn_day {
        width: 70px !important;
    }

    .futura_md_bt_12_16 {
        text-align: left;
        font: 8px/16px Futura Md BT;
        letter-spacing: 0;
    }

    .futura_lt_bt_18_21_green_menu {
        text-align: left;
        font: 12px/14px Futura Lt BT;
        letter-spacing: 0;
        color: var(--mainGreen) !important;
        opacity: 1;
    }

    .futura_lt_bt_18_21_black_menu {
        text-align: left;
        font: 12px/14px Futura Lt BT;
        letter-spacing: 0;
        color: var(--frameBlack) !important;
        opacity: 1;
    }

    .futura_lt_bt_18_24_white {
        text-align: left;
        font: 15px/19px Futura Lt BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_hv_bt_60_60_white {
        text-align: left;
        font: 35px/35px Futura Hv BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_36_10_white {
        text-align: left;
        font: 20px/8px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }
    .futura_md_bt_26_10_white {
        text-align: left;
        font: 15px/8px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .margin_top_20_avatar {
        margin-top: 15px;
    }

    .futura_lt_bt_18_21_black {
        text-align: left;
        font: 16px/18px Futura Lt BT;
        letter-spacing: 0;
        color: var(--frameBlack) !important;
        opacity: 1;
    }

    .futura_hv_bt_36_43_black {
        text-align: left;
        font: 26px/28px Futura Hv BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    .bg_green {
        background-color: var(--mainGreen);
        margin-left: 10px;
        height: 60px;
        width: 200px !important;
    }

    .futura_md_bt_24_10_white {
        text-align: left;
        font: 20px/8px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_24_29_green {
        text-align: left;
        font: 18px/19px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainGreen);
    }

    .futura_md_bt_24_29_white {
        text-align: left;
        font: 14px/16px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_18_21_white {
        text-align: left;
        font: 12px/13px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .content_text_container {
        top: 80%;
        -ms-transform: translateY(-80%);
        transform: translateY(-80%);
    }

    .left_50 {
        left: 30px !important;
    }


    .bg_color_btn {
        height: 35px !important;
    }


    .futura_hv_bt_22_26_black {
        text-align: left;
        font: 16px/18px Futura Hv BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    .futura_lt_bt_14_19_grey {
        text-align: left;
        font: 11px/13px Futura Lt BT;
        letter-spacing: 0;
        color: var(--mainGrey);
        opacity: 1;
    }

    .futura_md_bt_30_43_black {
        text-align: left;
        font: 15px/27px Futura Md BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    .margin_top_timeline_50 {
        margin-top: 10px;
    }

}


@media screen and (max-width: 1025px) {
    .padding_left_60_tl {
        padding-left: 60px;
    }

    .steps_timeline .step .title:before {
        left: 60px !important;
    }

    .steps_timeline {
        padding-left: 0 !important;
    }

    .content_text_container_bottom_mh,
    .content_text_container_bottom {
        -ms-transform: translateY(-30px);
        transform: translateY(-30px);
        height: 170px;
        background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    }
}

@media screen and (max-width: 768px) {
    .futura_lt_bt_18_24_white {
        text-align: left;
        font: 12px/16px Futura Lt BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_hv_bt_60_60_white {
        text-align: left;
        font: 18px/18px Futura Hv BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_36_10_white {
        text-align: left;
        font: 13px/5px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }
    .futura_md_bt_26_10_white {
        text-align: left;
        font: 12px/5px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .margin_top_20_avatar {
        margin-top: 8px;
    }

    .futura_lt_bt_18_21_black {
        text-align: left;
        font: 10px/13px Futura Lt BT;
        letter-spacing: 0;
        color: var(--frameBlack) !important;
        opacity: 1;
    }

    .futura_hv_bt_36_43_black {
        text-align: left;
        font: 16px/20px Futura Hv BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    .bg_green {
        background-color: var(--mainGreen);
        margin-left: 10px;
        height: 40px;
        width: 120px !important;
    }

    .futura_md_bt_24_10_white {
        text-align: left;
        font: 13px/5px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_24_29_green {
        text-align: left;
        font: 13px/14px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainGreen);
    }

    .left_50 {
        left: 15px !important;
    }

    .futura_hv_bt_30_50_black {
        text-align: left;
        font: 15px/25px Futura Hv BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    .futura_lt_bt_24_50_black {
        text-align: left;
        font: 12px/25px Futura Lt BT;
        letter-spacing: 0;
        color: var(--frameBlack) !important;
        opacity: 1;
    }

    .futura_md_bt_18_24_lightGray {
        text-align: left;
        font: 10px/12px Futura Md BT;
        letter-spacing: 0;
        color: var(--lightGray);
    }

    .futura_md_bt_18_21_lightGray {
        text-align: left;
        font: 10px/11px Futura Md BT;
        letter-spacing: 0;
        color: var(--lightGray);
    }

    .futura_hv_bt_30_32_black {
        text-align: left;
        font: 15px/16px Futura Hv BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    .futura_md_bt_24_29_white {
        text-align: left;
        font: 12px/14px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_18_21_white {
        text-align: left;
        font: 9px/10px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .margin_top_50 {
        margin-top: 25px;
    }

    .margin_top_20 {
        margin-top: 10px;
    }

    .padding_right_200 {
        padding-right: 0;
    }

    .text_right {
        text-align: center !important;
    }


    .futura_hv_bt_22_26_black {
        text-align: left;
        font: 11px/13px Futura Hv BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    .futura_lt_bt_14_19_grey {
        text-align: left;
        font: 9px/11px Futura Lt BT;
        letter-spacing: 0;
        color: var(--mainGrey);
        opacity: 1;
    }

    .futura_md_bt_30_43_black {
        text-align: left;
        font: 12px/22px Futura Md BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    .label-font {
        text-align: left;
        font: 15px/15px Futura Hv BT;
        letter-spacing: 0;
        color: var(--frameBlack);
    }

    li.destination_page_continent.level1 {
        margin-left: 2px;
        text-align: left;
        font: 10px/11px Futura Hv BT;
        letter-spacing: 0;
        color: var(--bgBlack);

    }

    li.destination_page_continent.level2 {
        margin-left: 2px;
        padding-left: 4px;

        text-align: left;
        font: 9px/11px Futura Hv BT;
        letter-spacing: 0;
        color: var(--bgBlack);
    }

    li.destination_page_continent.level3 {
        margin-left: 6px;
        padding-left: 4px;
        text-align: left;
        font: 8px/11px Futura Hv BT;
        letter-spacing: 0;
        color: var(--bgBlack);
    }

    li.destination_page_continent.level3-4.level3 {
        margin-left: 10px;
        text-align: left;
        font: 7px/11px Futura Hv BT;
        letter-spacing: 0;
        color: var(--bgBlack);
        padding-left: 6px;
    }

    li.destination_page_continent.level4 {
        margin-left: 10px;
        text-align: left;
        font: 7px/11px Futura Hv BT;
        letter-spacing: 0;
        color: var(--bgBlack);
        padding-left: 6px;
    }

    .padding_left_60_tl {
        padding-left: 30px;
    }

    .steps_timeline .step .title:before {
        left: 45px !important;
    }

    .steps_timeline {
        padding-left: 0 !important;
    }

    .content_text_container_bottom_mh,
    .content_text_container_bottom {
        -ms-transform: translateY(-30px);
        transform: translateY(-30px);
        height: 170px;
        background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    }
}


/* Mobile */
@media screen and (max-width: 500px) {
    .destinations_days_container {
        left: 77% !important;
        top: 3% !important;
    }

    .padding_left_15 {
        padding-left: 0 !important;
    }

    .steps_timeline {
        padding-left: 0 !important;
    }

    .padding_left_60_tl {
        padding-left: 60px;
    }

    .steps_timeline .step .title:before {
        left: 45px !important;
    }



    .content_text_container_bottom {
        -ms-transform: translateY(-30px);
        transform: translateY(-30px);
        height: 300px;
        background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    }

    .futura_lt_bt_18_24_white {
        text-align: left;
        font: 9px/16px Futura Lt BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }
}