

@media only screen and (max-width: 600px) {
	.MuiBreadcrumbs-li{
		font-size: 11px;
	}

	.MuiBreadcrumbs-ol{
		margin-bottom: 30px !important;
	}

	.viewGalleryBucket{
	    display: none !important;
	}
	.bucketTitle{
		font-size: 14px  !important;
		font-weight: 100 !important;
		margin-top: -20px;
	}

	.bucketName{
		margin-top: -60px;
	}

	.addPhotosEst{
		right: -40px;
		top: -20px;
		position: absolute;
	}

	.starBucket{
		display: none !important;
	}
	.bucketMenu{
		display: none !important;
	}

	.bucketContainer{
		padding: 20px;
	}
	.reviewsBucketListUserPost .textField{
		width: 88vw !important;
	}

	.bucketContainer .reviewContainer .reviews{
		display: block !important
	}

	
	.bucketContainer .reviewContainer .box_3{
		width: 90vw !important;
	}

	.bucketPost{
		display: block !important;
		margin-bottom: 50px;
		max-width: 90vw !important;
	}
  }