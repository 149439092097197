footer.footer_bottom {
    background: #777777;
    padding: 30px 20px 15px 20px;
    color: white;
    text-align: center;
    margin-top: 100px;
}

footer.footer_bottom .row p {
    margin-top: 5px;
}

footer.footer_bottom ul.social_icons {
    padding-left: 0;
}

footer.footer_bottom ul.social_icons li {
    list-style: none;
    display: inline-block;
    border-right: 2px solid white;
}


.thumbsUp {
    padding-left: 5px;
    padding-right: 10px;
    color: var(--mainWhite)
}

.googlePlusLink {
    padding: 1px 0 3px 0;
    background-color: white;
    margin-left: 10px;
}

.b_none {
    border: none !important;
}

.border_l {
    border-left: 2px solid white;
}

.b_right {
    border-right: 2px solid white !important;
}

.b_left {
    border-left: 2px solid white !important;
}

@media screen and (max-width: 768px) {

    .b_right {
        border-right: 0px solid white !important;
    }

    .b_left {
        border-left: 0px solid white !important;
    }
}