.reviewsBucketListUserPost {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    /* float: right; */
}

.reviewsBucketListUserPost .label {
    height: 30px;
    margin-left: 0px;
    font-size: 14px;
    font-weight: bold;
    color: #274674;
    font: 18px/14px Futura Lt BT;
}

.reviewsBucketListUserPost .input {
    flex-grow: 1;
}

.reviewsBucketListUserPost .textField {
    padding-Left: 2px;
    font-size: 14px;
    width: 400px;
    height: 200px,
}

.reviewsBucketListUserPost .MuiInputBase-inputMultiline{
    height: 197px;
    line-height: 27px;
}
.reviewsBucketListUserPost .submitButton
{
    font: 16px/16px Futura Md BT;
    color: var(--mainWhite);
    text-transform: none;
    height: 40px;
    width: 150px;
}

