/*  base font styling  */
@font-face {
  font-family: 'Futura Md BT';
  src: url('fonts/Futura-Medium-bt.ttf');
  src: url('fonts/Futura-Medium-bt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura XBlk BT';
  src: url('fonts/Futura-Extra-Black-font.ttf');
  src: url('fonts/Futura-Extra-Black-font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Lt BT';
  src: url('fonts/Futura-Light-font.ttf');
  src: url('fonts/Futura-Light-font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Hv BT';
  src: url('fonts/Futura-Heavy-font.ttf');
  src: url('fonts/Futura-Heavy-font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h2,
h4,
p,
span {
  font-family: 'Futura Lt BT';
}

/**/

/*  base color  */

:root {
  --bgBlack: #2C2C2C;
  --frameBlack: #191919;
  --mainGreen: #19BC9B;
  --mainWhite: #FFFFFF;
  --mainOrange: #fb884d;
  --mainBlue: #0D3A7D;
  --mainYellow: #ffa400;
  --mainDark: #232528;
  --mainGrey: #707070;
  --lightGraySeparator: #E8E8E8;
  --darkBlack: #000000;
  --lightBlue: #009ffd;
  --lightGray: #707070;
  --lightGrayF: #F8F8F8;
}

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.se-pre-con svg {
  margin: 0;
  position: absolute;
  top: 49%;
  left: 47%;
  -ms-transform: translate(-47%, -49%);
  transform: translate(-47%, -49%);
}

body {
  margin: 0;
  padding: 0;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 500px !important;
  margin-bottom: 100px !important;
}

select:focus,
select:hover,
div:focus,
textarea:focus,
input:focus {
  outline: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.text_main_white {
  color: var(--mainWhite)
}

.text_main_black {
  color: var(--frameBlack)
}

.text_main_green {
  color: var(--mainGreen)
}

.App-link {
  color: var(--mainGreen);
}

.text_main_blue {
  color: var(--mainBlue);
}

.text-bright {
  color: var(--lightBlue);
}

.text-white {
  color: var(--mainWhite);
}

.text_red {
  color: red
}

.draggable {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/openhand.cur'), all-scroll;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.draggable:active {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.bgGreenHover:hover {
  background-color: rgba(25, 188, 155, 0.2);
}

/* futura heavy */
.futura_hv_bt_45_45 {
  text-align: left;
  font: 45px/45px Futura Hv BT;
  letter-spacing: 0;
}

.futura_hv_bt_48_48 {
  text-align: left;
  font: 48px/48px Futura Hv BT;
  letter-spacing: 0;
}

.futura_hv_bt_36_36 {
  text-align: left;
  font: 36px/36px Futura Hv BT;
  letter-spacing: 0;
}

.futura_hv_bt_30_40 {
  text-align: left;
  font: 30px/40px Futura Hv BT;
  letter-spacing: 0;
}

.futura_hv_bt_24_40 {
  text-align: left;
  font: 24px/40px Futura Hv BT;
  letter-spacing: 0;
}

.futura_hv_bt_24_28 {
  text-align: left;
  font: 24px/28px Futura Hv BT;
  letter-spacing: 0;
}

.futura_hv_bt_30_30_white {
  text-align: left;
  font: 30px/30px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_24_30_white {
  text-align: left;
  font: 24px/30px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_24_72_white {
  text-align: left;
  font: 24px/72px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_24_26_white {
  text-align: left;
  font: 24px/26px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_18_24_white {
  text-align: left;
  font: 18px/24px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_18_20_white {
  text-align: left;
  font: 18px/20px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_36_72_white {
  text-align: left;
  font: 36px/72px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_30_70_white {
  text-align: left;
  font: 30px/70px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_50_72_white {
  text-align: left;
  font: 50px/72px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_60_60_white {
  text-align: left;
  font: 60px/60px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_18_26_white {
  text-align: left;
  font: 18px/26px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_hv_bt_18_26_black {
  text-align: left;
  font: 18px/26px Futura Hv BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}


.futura_hv_bt_22_26_black {
  text-align: left;
  font: 22px/26px Futura Hv BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_hv_bt_22_26 {
  text-align: left;
  font: 22px/26px Futura Hv BT;
  letter-spacing: 0;
}

.futura_hv_bt_30_30_black {
  text-align: left;
  font: 30px/30px Futura Hv BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_hv_bt_36_43_black {
  text-align: left;
  font: 36px/43px Futura Hv BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_hv_bt_30_32_black {
  text-align: left;
  font: 30px/32px Futura Hv BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_hv_bt_30_32 {
  text-align: left;
  font: 30px/32px Futura Hv BT;
  letter-spacing: 0;
}

.futura_hv_bt_30_50_black {
  text-align: left;
  font: 30px/50px Futura Hv BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_hv_bt_11_17_green {
  text-align: left;
  font: 11px/17px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}

.futura_hv_bt_16_19_green {
  text-align: left;
  font: 16px/19px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainGreen);
  cursor: pointer;
}

.futura_hv_bt_14_22_green {
  text-align: left;
  font: 14px/22px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}

.futura_hv_bt_18_21_black_menu {
  text-align: left;
  font: 18px/21px Futura Hv BT;
  letter-spacing: 0;
  color: var(--frameBlack);
  cursor: pointer;
}

/* end fututa heavy */
/* futura light */
.futura_lt_bt_24_30_white {
  text-align: left;
  font: 24px/30px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_lt_bt_18_24_white {
  text-align: left;
  font: 18px/24px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_lt_bt_18_24_white_menu {
  text-align: left;
  font: 18px/24px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainWhite) !important;
}

.futura_lt_bt_12_14_white {
  text-align: left;
  font: 12px/14px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_lt_bt_14_19_grey {
  text-align: left;
  font: 14px/19px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainGrey);
  opacity: 1;
}

.futura_lt_bt_14_16_grey {
  text-align: left;
  font: 14px/16px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainGrey);
  opacity: 1;
}

.futura_lt_bt_16_18_grey {
  text-align: left;
  font: 16px/18px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainGrey);
  opacity: 1;
}

.futura_lt_bt_8_10_green {
  text-align: left;
  font: 8px/10px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainGreen);
  opacity: 1;
}

.futura_lt_bt_18_21_black {
  text-align: left;
  font: 18px/21px Futura Lt BT;
  letter-spacing: 0;
  color: var(--frameBlack) !important;
  opacity: 1;
}

.futura_lt_bt_18_21_grey {
  text-align: left;
  font: 18px/21px Futura Lt BT;
  letter-spacing: 0;
  color: var(--lightGray) !important;
  opacity: 1;
}

.futura_lt_bt_18_24_green_menu {
  text-align: left;
  font: 18px/24px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainGreen) !important;
  opacity: 1;
}

.futura_lt_bt_18_21_green_menu {
  text-align: left;
  font: 18px/21px Futura Lt BT;
  letter-spacing: 0;
  color: var(--mainGreen) !important;
  opacity: 1;
}

.futura_lt_bt_18_21_black_menu {
  text-align: left;
  font: 18px/21px Futura Md BT;
  letter-spacing: 0;
  color: var(--frameBlack) !important;
  opacity: 1;
}

.futura_lt_bt_24_50_black {
  text-align: left;
  font: 24px/50px Futura Lt BT;
  letter-spacing: 0;
  color: var(--frameBlack) !important;
  opacity: 1;
}

.futura_lt_bt_24_24 {
  text-align: left;
  font: 24px/24px Futura Lt BT;
  letter-spacing: 0;
}

.futura_lt_bt_18_32 {
  text-align: left;
  font: 18px/32px Futura Lt BT;
  letter-spacing: 0;
}

.futura_lt_bt_20_30 {
  text-align: left;
  font: 20px/30px Futura Lt BT;
  letter-spacing: 0;
}


.futura_lt_bt_18_24 {
  text-align: left;
  font: 18px/24px Futura Lt BT;
  letter-spacing: 0;
}

.futura_lt_bt_12_20 {
  text-align: left;
  font: 12px/20px Futura Lt BT;
  letter-spacing: 0;
}

.futura_lt_bt_18_32 {
  text-align: left;
  font: 18px/32px Futura Lt BT;
  letter-spacing: 0;
}


/*  end futura light */

/* futura medium */
.futura_md_bt_12_16 {
  text-align: left;
  font: 12px/16px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_14_16_grey {
  text-align: left;
  font: 14px/16px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGrey);
}

.futura_md_bt_14_22_grey {
  text-align: left;
  font: 14px/22px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGrey);
}

.futura_md_bt_18_21_lightGray {
  text-align: left;
  font: 18px/21px Futura Md BT;
  letter-spacing: 0;
  color: var(--lightGray);
}

.futura_md_bt_18_26 {
  text-align: left;
  font: 18px/26px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_18_24_lightGray {
  text-align: left;
  font: 18px/24px Futura Md BT;
  letter-spacing: 0;
  color: var(--lightGray);
}

.futura_md_bt_18_32 {
  text-align: left;
  font: 18px/32px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_13_16_green {
  text-align: left;
  font: 13px/16px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}


.futura_md_bt_13_19_green {
  text-align: left;
  font: 13px/19px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGrey);
}

.futura_md_bt_14_16_green {
  text-align: left;
  font: 14px/16px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}
.futura_md_bt_14_16_orange {
  text-align: left;
  font: 14px/16px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainOrange);
}

.futura_md_bt_14_16 {
  text-align: left;
  font: 14px/16px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_16_19_green {
  text-align: left;
  font: 16px/19px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
  opacity: 1;
}

.futura_md_bt_16_36_green {
  text-align: left;
  font: 16px/36px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}

.futura_md_bt_18_18_green {
  text-align: left;
  font: 18px/18px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}

.futura_md_bt_24_29_green {
  text-align: left;
  font: 24px/29px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}

.futura_md_bt_18_72_green {
  text-align: left;
  font: 18px/72px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}

.futura_md_bt_19_70_green {
  text-align: left;
  font: 19px/70px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}

.futura_md_bt_6_7_white {
  text-align: left;
  font: 6px/7px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_12_15_white {
  text-align: left;
  font: 12px/15px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_14_22_white {
  text-align: left;
  font: 14px/22px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_14_24_white {
  text-align: left;
  font: 14px/24px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_16_22_white {
  text-align: left;
  font: 16px/22px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_16_21 {
  text-align: left;
  font: 16px/21px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_16_25_white {
  text-align: left;
  font: 16px/25px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_18_14_white {
  text-align: left;
  font: 18px/14px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_24_24_white {
  text-align: left;
  font: 24px/24px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_20_20 {
  text-align: left;
  font: 20px/20px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_30_30 {
  text-align: left;
  font: 30px/30px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_24_24 {
  text-align: left;
  font: 24px/24px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_24_24_green {
  text-align: left;
  font: 24px/24px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainGreen);
}

.futura_md_bt_10_10 {
  text-align: left;
  font: 10px/10px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_14_10 {
  text-align: left;
  font: 14px/10px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_18_14 {
  text-align: left;
  font: 18px/24px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_18_20_white {
  text-align: left;
  font: 18px/20px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_18_21_white {
  text-align: left;
  font: 18px/21px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_18_30_white {
  text-align: left;
  font: 18px/30px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_36_72_white {
  text-align: left;
  font: 36px/72px Futura Md BT;
  ;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_11_14_black {
  text-align: left;
  font: 11px/14px Futura Md BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_md_bt_14_16_black {
  text-align: left;
  font: 14px/16px Futura Md BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_md_bt_30_43_black {
  text-align: left;
  font: 30px/43px Futura Md BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_hv_bt_30_43_black {
  text-align: left;
  font: 30px/43px Futura HV BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_md_bt_14_14_black {
  text-align: left;
  font: 14px/14px Futura Md BT;
  letter-spacing: 0;
  color: var(--frameBlack);
}

.futura_md_bt_14_14 {
  text-align: left;
  font: 14px/20px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_18_20_white_menu {
  text-align: left;
  font: 18px/20px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite) !important;
}

.futura_md_bt_20_24_white {
  text-align: left;
  font: 20px/24px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_24_29_white {
  text-align: left;
  font: 24px/29px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_24_20_white {
  text-align: left;
  font: 24px/20px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_24_10_white {
  text-align: left;
  font: 24px/10px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_24_26_white {
  text-align: left;
  font: 24px/26px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_26_10_white {
  text-align: left;
  font: 26px/10px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_36_10_white {
  text-align: left;
  font: 36px/10px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_38_26_white {
  text-align: left;
  font: 38px/26px Futura Md BT;
  letter-spacing: 0;
  color: var(--mainWhite);
}

.futura_md_bt_14_20 {
  text-align: left;
  font: 14px/20px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_36_36 {
  text-align: left;
  font: 36px/36px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_24_32 {
  text-align: left;
  font: 24px/32px Futura Md BT;
  letter-spacing: 0;
}

.futura_md_bt_70_100 {
  text-align: left;
  font: 70px/100px Futura Md BT;
  letter-spacing: 0;
}

/* end futura medium */
.margin_0 {
  margin: 0
}

.padding_top_15 {
  padding-top: 15px;
}

.padding_top_20 {
  padding-top: 20px;
}

.padding_bottom_15 {
  padding-bottom: 15px;
}

.padding_bottom_40 {
  padding-bottom: 40px;
}

.padding_left_map_15 {
  padding-left: 15px;
}

.noTripsFound {
  height: 100px;
  padding-top: 50px;
  padding-bottom: 450px;
  padding-left: 40px
}

.margin_right_65 {
  margin-right: 65px;
}

.margin_right_60 {
  margin-right: 60px;
}

.margin_right_50 {
  margin-right: 50px;
}

.margin_right_45 {
  margin-right: 45px;
}

.margin_right_35 {
  margin-right: 35px;
}

.margin_right_30 {
  margin-right: 30px;
}

.hotel_margin_right {
  margin-right: 30px;
}

.margin_right_20 {
  margin-right: 20px;
}

.margin_right_15 {
  margin-right: 15px;
}

.margin_right_10 {
  margin-right: 10px;
}

.margin_right_5 {
  margin-right: 5px;
}

.margin_right_minus_5 {
  margin-right: -5px;
}

.margin_top_70 {
  margin-top: 70px;
}

.margin_top_60 {
  margin-top: 60px;
}

.margin_top_50 {
  margin-top: 50px;
}

.margin_top_40 {
  margin-top: 40px;
}

.margin_top_30 {
  margin-top: 30px;
}

.margin_top_20 {
  margin-top: 20px;
}

.margin_top_10 {
  margin-top: 10px;
}

.margin_top_5 {
  margin-top: 5px;
}

.margin_top_2 {
  margin-top: 2px;
}

.margin_bottom_5 {
  margin-bottom: 5px;
}

.margin_bottom_10 {
  margin-bottom: 10px;
}

.margin_bottom_20 {
  margin-bottom: 20px;
}

.margin_bottom_30 {
  margin-bottom: 30px;
}

.margin_bottom_40 {
  margin-bottom: 40px;
}

.margin_bottom_50 {
  margin-bottom: 50px;
}

.margin_bottom_60 {
  margin-bottom: 60px;
}

.margin_bottom_70 {
  margin-bottom: 70px;
}

.margin_bottom_80 {
  margin-bottom: 80px;
}

.margin_bottom_90 {
  margin-bottom: 90px;
}

.margin_bottom_100 {
  margin-bottom: 100px;
}

.margin_bottom_110 {
  margin-bottom: 110px;
}

.margin_bottom_120 {
  margin-bottom: 120px;
}

.margin_left_80 {
  margin-left: 80px;
}

.margin_left_70 {
  margin-left: 70px;
}

.margin_left_60 {
  margin-left: 60px;
}

.margin_left_65 {
  margin-left: 65px;
}

.margin_left_50 {
  margin-left: 50px;
}


.margin_left_45 {
  margin-left: 45px;
}

.margin_left_40 {
  margin-left: 40px;
}

.margin_left_35 {
  margin-left: 35px;
}

.margin_left_30 {
  margin-left: 30px;
}

.margin_left_20 {
  margin-left: 20px;
}

.margin_left_15 {
  margin-left: 15px;
}

.margin_left_10 {
  margin-left: 10px;
}

.margin_left_5 {
  margin-left: 5px;
}

.margin_left_minus_5 {
  margin-left: -5px;
}

.margin_left_3 {
  margin-left: 3px;
}

/* menu */
.margin_left_30_menu {
  margin-left: 30px;
}

.margin_left_20_menu {
  margin-left: 20px;
}

.margin_right_30_menu {
  margin-right: 30px;
}

.margin_right_20_menu {
  margin-right: 20px;
}

.margin_right_10_icon {
  margin-right: 10px;
}

.img_fit_cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
  -o-object-position: 50% 50% !important;
  object-position: 50% 50% !important;
}

.bg_gradient_top {
  /* background: linear-gradient(to bottom, rgba(25,25,25,0.7) 0%, rgba(25,25,25,0.3) 70%, rgba(25,25,25,0) 100%); */
  background-color: rgba(25, 25, 25, 0.2);
  padding: 5px;
}

.bg_gradient_bottom {
  background-color: rgba(25, 25, 25, 0.2);
  /* background: linear-gradient(to top, rgba(25,25,25,0.7) 0%, rgba(25,25,25,0.3) 70%, rgba(25,25,25,0) 100%); */
}

.mask_block {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  align-items: center;
  background-color: rgba(25, 25, 25, 0.2);
  z-index: 2;
}

.mask_block_descriptioin {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  align-items: center;
  background-color: #FFFFFF80;
  z-index: 2;
}

.mask_left_btn {
  top: 50%;
  width: 100px;
  left: 0;
  position: absolute;
  align-items: center;
}

.mask_title_btn {
  top: 43%;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
}

.mask_landing_title_btn {
  top: 20%;
  left: 15%;
  right: 0;
  position: absolute;
  text-align: left;
}

.mask_avatar_btn {
  top: 34%;
  left: 12.5%;
  position: absolute;
  align-items: center;
}

.mask_right_btn {
  top: 50%;
  width: 100px;
  right: 0;
  position: absolute;
  align-items: center;
}

.mask_map_activity_icon {
  top: 76%;
  left: 30px;
  position: absolute;
  align-items: center;
}

.mask_map_activity_icon_description {
  top: 0px;
  left: 15px;
  position: absolute;
  align-items: center;
}

.mask_map_activity_name {
  top: 85%;
  left: 30px;
  position: absolute;
  align-items: center;
}

.mask_activity_name {
  top: 88%;
  left: 10px;
  position: absolute;
  align-items: center;
}

.mask_schedule_activity_day {
  top: 5%;
  left: 10px;
  position: absolute;
  align-items: center;
}

.mask_hotel_name {
  top: 88%;
  left: 20px;
  position: absolute;
  align-items: center;
}

.times_map_icon {
  top: 3%;
  left: 96%;
  position: absolute;
  align-items: center;
}

.trip_title_map {
  top: 93%;
  left: 3%;
  position: absolute;
  align-items: center;
}

.z_index_1 {
  z-index: 1;
}

.z_index_2 {
  z-index: 2;
}

.z_index_3 {
  z-index: 3;
}

.title_bar_root {
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  position: absolute;
  align-items: center;
}

.bottom_bar_root {
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  position: absolute;
  align-items: center;
}

.mask_bar_root {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  position: absolute;
  align-items: center;
  background-color: rgba(25, 25, 25, 0.2);
}

.padding_top_40 {
  padding-top: 40px;
}

.padding_top_50 {
  padding-top: 50px;
}

.flight_padding_top {
  padding-top: 70px;
}

.padding_right_200 {
  padding-right: 200px !important;
}

.private_other_trip {
  padding-left: 7%;
  padding-right: 7%;
}

.padding_left_50 {
  padding-left: 50px;
}

.padding_left_40 {
  padding-left: 40px;
}

.padding_left_30 {
  padding-left: 30px;
}

.padding_left_20 {
  padding-left: 20px;
}

.padding_left_15 {
  padding-left: 15px;
}

.padding_left_10 {
  padding-left: 10px;
}

.padding_left_0 {
  padding-left: 0px;
}

.padding_left_right_15 {
  padding-left: 15px;
  padding-right: 15px
}

.padding_left_right_10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding_left_right_0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}


.padding_right_40 {
  padding-right: 40px;
}

.padding_right_30 {
  padding-right: 30px;
}

.activity_padding_right_30 {
  padding-right: 30px;
}

.padding_right_20 {
  padding-right: 20px;
}

.padding_right_15 {
  padding-right: 15px;
}

.padding_right_10 {
  padding-right: 10px;
}

.padding_right_5 {
  padding-right: 5px;
}

.padding_bottom_10 {
  padding-bottom: 10px;
}

.padding_bottom_20 {
  padding-bottom: 20px;
}

.padding_0 {
  padding: 0;
}

.padding_5 {
  padding: 5px;
}

.padding_10 {
  padding: 10px;
}

/* card */

.card_amimated {
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
}

.card_amimated:hover {
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);
}



.MuiButtonBase-root {
  outline: none !important;
}

/* display box */

.box_1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

}

.box_2 {
  overflow: hidden;
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}

.box_3 {
  overflow: hidden;
  display: -webkit-box;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

}

.box_4 {
  overflow: hidden;
  display: -webkit-box;

  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

}

.box_3_wrap {
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

}

.box_10 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;

}

.line_separator {
  border: 1px solid var(--bgBlack);
  background: var(--bgBlack);
  opacity: 1;
}

.line_separator_frame_black {
  border: 1px solid var(--frameBlack);
  background: var(--frameBlack);
  opacity: 1;
}

.line_separator_w {
  border: 1px solid var(--lightGraySeparator);
  background: var(--lightGraySeparator);
  opacity: 1;
}

.line_separator_green {
  border: 1px solid var(--mainGreen);
  background: var(--mainGreen);
  opacity: 1;
}

.line_under {
  border: 3px solid var(--mainGreen);
  background: var(--mainGreen);
  opacity: 1;
  margin: 0px;
}


/* display box end */
/* flex: 

d-inline-flex flex-wrap
*/
.w_120 {
  width: 120px;
}

.flex_1 {
  flex: 1;
}

.flex_grow_1 {
  flex-grow: 1;
}

.flex_grow_2 {
  flex-grow: 2;
}

.flex_grow_3 {
  flex-grow: 3;
}

.flex_grow_4 {
  flex-grow: 4;
}

.flex_grow_5 {
  flex-grow: 5;
}

.flex_wrap {
  flex-wrap: wrap;
}

.justify_content_right {
  justify-content: flex-end;
}

.justify_content_center {
  justify-content: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vertical_center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.content_text_container {
  position: absolute;
  top: 85%;
  -ms-transform: translateY(-85%);
  transform: translateY(-85%);
  left: 5%;
  right: 15%;
  bottom: 0;
  z-index: 1;
}

.content_text_map_right_container {
  position: absolute;
  top: 87%;
  -ms-transform: translateY(-87%);
  transform: translateY(-87%);
  left: 8%;
  right: 15%;
  bottom: 0;
  z-index: 1;
}

.content_text_container_bottom {
  position: absolute;
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  left: 5px;
  right: 5px;
  bottom: 0;
  z-index: 1;
  height: 200px;
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}

.content_text_container_bottom_mh {
  position: absolute;
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  left: 15px;
  right: 15px;
  bottom: 10px;
  z-index: 1;
  height: 110px;
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}

  input::-ms-reveal,
   input::-ms-clear {
      display: none;
    }

.registerCaptcha{
  margin-top: 40px;
}


.bucket-menu{
  white-space: nowrap !important;
  overflow: hidden !important;
  display: block !important;
  text-overflow: ellipsis;
}

.content_container {
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding: 0;
  margin: 0;
}

.content_container_relative {
  position: relative;
  z-index: 0;
  padding: 0;
  margin: 0;
}

.min_width_100 {
  min-width: 100%;
}

.grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  transform: scale(1.02);
}

.grow2 {
  transition: all .2s ease-in-out;
}

.grow2:hover {
  transform: scale(1.05);
}

.grow3 {
  transition: all .2s ease-in-out;
}

.grow3:hover {
  transform: scale(1.20);
}

/* override bootstrap */
.slick-prev:before {
  content: '' !important;
}

.slick-next:before {
  content: '' !important;
}

.left_element .slick-track {
  width: auto !important;
}

.slick-prev {
  margin-left: -5px;
  z-index: 2;
}

.slick-next {
  margin-right: 35px;
  z-index: 2;
}

#google_map_wrap iframe+div,
#google_map_wrap iframe+div+div+div,
#google_map_wrap iframe+div+div+div+div,
#google_map_wrap iframe+div+div+div+div+div,
#google_map_wrap>div>div>div>div+div>div:nth-child(2),
#google_map_wrap>div>div>div>div+div>div:nth-child(4),
#google_map_wrap>div>div>div>div+div>div:nth-child(5),
#google_map_wrap>div>div>div>div+div>div:nth-child(6),
#google_map_wrap>div>div>div>div+div>div:nth-child(11),
#google_map_wrap>div>div>div>div+div>div:nth-child(13),
#google_map_wrap iframe+div+div {
  bottom: -25px !important;
}

#google_map_wrap>div>div>div>div+div>div:nth-child(10) {
  top: -50px !important;
}

#google_map_wrap>div>div>div>div+div>div:nth-child(9) {
  top: 89% !important;
}

.wh_180 {
  height: 180px;
  width: 180px;
}

.wh_70 {
  height: 70px;
  width: 70px;
}

.w1080_h240 {
  width: 1080px;
  height: 240px;
}

.bg_color_btn_black {
  background: var(--frameBlack) 0% 0% no-repeat padding-box;
  cursor: pointer;
  color: var(--mainWhite);
  padding: 1px;
  border: 1px solid #2C2C2C;
}

.bg_color_btn_white {
  background: var(--mainWhite) 0% 0% no-repeat padding-box;
  cursor: pointer;
  color: var(--bgBlack);
  padding: 1px;
  border: 1px solid #E8E8E8;
}

.destination_black_active {
  background: var(--mainGreen) 0% 0% no-repeat padding-box !important;
  color: var(--frameBlack) !important;
}

.destination_white_active {
  background: var(--mainGreen) 0% 0% no-repeat padding-box !important;
  color: var(--mainWhite) !important;
}

.rating_color .MuiRating-root {
  opacity: 1 !important;
  color: var(--mainGreen);
}

.timeline_rating_color .MuiRating-root {
  opacity: 1 !important;
  color: var(--mainGreen);
}

.timeline_rating_color .MuiRating-iconEmpty {
  opacity: 0.6 !important;
  color: var(--mainGreen);
}

.star_color .MuiRating-root {
  opacity: 1 !important;
  color: var(--mainWhite);
}

.star_color .MuiRating-iconEmpty {
  opacity: 0.6 !important;
  color: var(--mainWhite);
}

.star_color .MuiRating-iconActive {
  transform: scale(1);
}


.schedule_margin_left {
  margin-left: 40px;
}

.scroller_hotel::-webkit-scrollbar {
  width: 5px;
  /* background-color: #19BC9B; */
}


.margin_left_col_2 {
  margin-left: 15%;
}

.margin_right_col_2 {
  margin-right: 15%;
}

.padding_right_col_1 {
  padding-right: 6.5%;
}

.hr_line {
  margin-top: 23.75px;
  margin-left: 10px;
  margin-right: 10px;
  width: 25px;
  border-top: 1px solid #19BC9B;
}


.menu_link_title_text_member_trip_black {
  font: 16px/0px Futura Hv BT;
  letter-spacing: 0;
  color: #191919 !important;
  background-color: initial;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  height: 85px;
  margin-top: 20px;
  padding-top: 0;
}

.menu_link_title_text_member_trip_black>p {
  vertical-align: middle;
}

.menu_link_title_text_member_trip_black:hover {
  color: var(--mainGreen) !important;
  cursor: pointer;
  border-bottom: 8px solid var(--mainGreen);
}

.menu_link_title_text_member_trip {
  font: 16px/0px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite) !important;
  background-color: initial;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  height: 85px;
  margin-top: 20px;
  padding-top: 0;
}

.menu_link_title_text_member_trip>p {
  vertical-align: middle;
}

.menu_link_title_text_member_trip:hover {
  color: var(--mainGreen) !important;
  cursor: pointer;
  border-bottom: 8px solid var(--mainGreen);
}


.menu_link_title_text_create_account {
  font: 18px/18px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainGrey);
  background-color: initial;
  opacity: 1;
  text-align: center;
  height: 35px;
  margin-top: 15px;
  padding-top: 0;
  border-bottom: 5px solid var(--mainGrey);
  padding-left: 15px;
  padding-right: 15px;
}


.menu_link_title_text_create_account_active {
  font: 18px/18px Futura Hv BT;
  letter-spacing: 0;
  background-color: initial;
  opacity: 1;
  text-align: center;
  height: 35px;
  margin-top: 15px;
  padding-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  color: var(--mainGreen);
  border-bottom: 5px solid var(--mainGreen);
}

.join_muvment_button {
  background-color: var(--mainGreen);
  padding-left: 15px;
  height: 60px;
  width: 200px !important;
}

@media screen and (max-width: 1366px) {

  .hidden_1366 {
    display: none;
  }

  .menu_link_title_text_create_account {
    font: 11px/11px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainGrey);
    background-color: initial;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    height: 25px;
    margin-top: 15px;
    border-bottom: 5px solid var(--mainGrey);
    padding-left: 10px;
    padding-right: 10px;
  }


  .menu_link_title_text_create_account_active {
    font: 11px/11px Futura Hv BT;
    letter-spacing: 0;
    background-color: initial;
    opacity: 1;
    text-align: center;
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--mainGreen);
    cursor: pointer;
    border-bottom: 5px solid var(--mainGreen);
  }
}

@media screen and (max-width: 1025px) {
  .content_text_map_right_container {
    top: 83%;
    -ms-transform: translateY(-83%);
    transform: translateY(-83%);
    left: 10%;
  }

  .mask_left_btn,
  .mask_right_btn {
    width: 50px;
  }

  #google_map_wrap>div>div>div>div+div>div:nth-child(9) {
    top: 80% !important;
  }

  .mask_map_activity_icon {
    top: 73%;
  }

  .menu_link_title_text_member_trip {
    font: 12px/0px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainWhite) !important;
    background-color: initial;
    font-weight: bold;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    height: 75px;
    margin-top: 10px;
    padding-top: 0;
  }

  .mask_avatar_btn {
    top: 34%;
    left: 8.5%;
    position: absolute;
    align-items: center;
  }

  .activity_padding_right_30 {
    padding-right: 10px;
  }

  .futura_md_bt_20_20 {
    font: 15px/15px Futura Md BT;
  }

  .futura_md_bt_30_30 {
    font: 25px/25px Futura Md BT;
  }

  .futura_md_bt_18_32 {
    font: 9px/16px Futura Md BT;
  }

  .futura_lt_bt_18_32 {
    font: 9px/16px Futura Lt BT;
  }

  .futura_hv_bt_45_45 {
    font: 22.5px/22.5px Futura Hv BT;
  }

  .futura_hv_bt_48_48 {
    font: 14px/24px Futura Hv BT;
  }

  .futura_hv_bt_36_36 {
    font: 18px/18px Futura Hv BT;
  }

  .futura_hv_bt_30_40 {
    font: 15px/20px Futura Hv BT;
  }

  .futura_hv_bt_24_40 {
    font: 12px/20px Futura Hv BT;
  }

  .futura_hv_bt_24_28 {
    font: 12px/16px Futura Hv BT;
  }

  .futura_md_bt_24_24 {
    font: 12px/12px Futura Md BT;
  }

  .futura_md_bt_20_20 {
    font: 10px/10px Futura Md BT;
  }

  .futura_md_bt_30_30 {
    font: 15px/15px Futura Md BT;
  }

  .futura_md_bt_14_20 {
    font: 7px/10px Futura Md BT;
  }

  .futura_md_bt_36_36 {
    font: 18px/18px Futura Md BT;
  }

  .futura_lt_bt_24_24 {
    font: 12px/12px Futura Lt BT;
  }

  .futura_lt_bt_18_24 {
    font: 9px/12px Futura Lt BT;
  }

  .futura_lt_bt_12_20 {
    font: 6px/10px Futura Lt BT;
  }

  .futura_lt_bt_18_32 {
    font: 9px/16px Futura Lt BT;
  }

  .futura_md_bt_24_32 {
    font: 12px/16px Futura Md BT;
  }

  .futura_md_bt_38_26_white {
    font: 19px/13px Futura Md BT;
  }

  .futura_hv_bt_14_22_green {
    font: 7px/11px Futura Hv BT;
  }

  .hr_line {
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
    width: 10px;
    border-top: 1px solid #19BC9B;
  }

  .hotel_margin_right {
    margin-right: 15px;
  }

  .futura_hv_bt_30_32 {
    font: 15px/16px Futura Hv BT;
  }

  .futura_md_bt_14_14 {
    font: 7px/10px Futura Md BT;
  }

  .futura_hv_bt_11_17_green {
    font: 5.5px/8.5px Futura Hv BT;
  }

  .futura_hv_bt_22_26 {
    font: 11px/13px Futura Hv BT;
  }

  .futura_lt_bt_14_19_grey {
    font: 7px/9.5px Futura Lt BT;
  }

  .private_other_trip {
    padding-left: 0;
    padding-right: 0;
  }

  .futura_md_bt_70_100 {
    font: 20px/30px Futura Md BT;
  }

  .futura_md_bt_18_26 {
    font: 10px/13px Futura Md BT;
  }

  .join_muvment_button {
    background-color: var(--mainGreen);
    padding-left: 5px;
    height: 25px;
    width: 105px !important;
  }

  .futura_lt_bt_20_30 {
    font: 10px/15px Futura Lt BT;
  }


  .menu_link_title_text_create_account {
    font: 11px/11px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainGrey);
    background-color: initial;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    height: 25px;
    margin-top: 15px;
    border-bottom: 5px solid var(--mainGrey);
    padding-left: 10px;
    padding-right: 10px;
  }


  .menu_link_title_text_create_account_active {
    font: 11px/11px Futura Hv BT;
    letter-spacing: 0;
    background-color: initial;
    opacity: 1;
    text-align: center;
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--mainGreen);
    cursor: pointer;
    border-bottom: 5px solid var(--mainGreen);
  }

  .futura_md_bt_16_21 {
    font: 9px/11px Futura Md BT;
  }
}

@media screen and (max-width:900px) {

  .hidden-xs {
    display: none;
  }

}

@media screen and (max-width: 500px)

/* Mobile */
  {
  .padding_0_mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .padding_left_20 {
    padding-left: 0 !important;
  }

  .padding_right_20 {
    padding-right: 0 !important;
  }

  .padding_125rem_lr_mobile {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .content_text_map_right_container {
    top: 80%;
    -ms-transform: translateY(-80%);
    transform: translateY(-80%);
    left: 5%;
  }

  .padding_left_map_15 {
    margin-left: 0;
  }

  .mask_left_btn,
  .mask_right_btn {
    width: 30px;
  }

  #google_map_wrap>div>div>div>div+div>div:nth-child(9) {
    top: 80% !important;
  }


  .schedule_margin_left {
    margin-left: 10px;
  }


  .menu_link_title_text_member_trip {
    font: 10px/0px Futura Hv BT;
    height: 20px;
    margin-top: 20px;
    padding-top: 0;
  }



  .mask_avatar_btn {
    top: 34%;
    left: 8.5%;
  }


  .margin_right_30 {
    margin-right: 0;
  }

  .flight_padding_top {
    padding-top: 0;
  }

  .times_map_icon {
    top: 8%;
    left: 2%;
  }

  .futura_md_bt_24_24_green {
    font: 12px/12px Futura Md BT;
  }


}




@media screen and (max-width: 411px) {

  .menu_link_title_text_create_account {
    font: 11px/11px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainGrey);
    background-color: initial;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    height: 25px;
    margin-top: 15px;
    border-bottom: 5px solid var(--mainGrey);
    padding-left: 10px;
    padding-right: 10px;
  }


  .menu_link_title_text_create_account_active {
    font: 11px/11px Futura Hv BT;
    letter-spacing: 0;
    background-color: initial;
    opacity: 1;
    text-align: center;
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--mainGreen);
    cursor: pointer;
    border-bottom: 5px solid var(--mainGreen);
  }

}



@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 0px;
  padding: 0px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.gm-style .gm-style-iw-d {
  box-sizing: border-box;
  overflow: hidden !important;
}

.gm-style-iw-t button {
  display: none !important;
}

#birthday+div>button {
  color: #B1B1B1;
}

#SRLLightbox {
  /* top: "100px";
  height: calc(100% - 100px) !important; */
  z-index: 1021 !important;
}


* {
  scrollbar-width: thin;
  scrollbar-color: #19BC9B var(--lightGraySeparator);
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
  color-adjust: exact !important;                 /*Firefox*/
}


*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--lightGraySeparator);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--lightGraySeparator);
  border-radius: 20px;
  border: 3px solid #19BC9B;
}

.bucketListName {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}


.gm-style .gm-style-iw-c {
  padding:0px !important
}