.past_trip_all {
    flex: 1;
    text-align: right;
}

.past_trip_container {
    display: flex !important;
    display: -webkit-flex !important;
}


.schedulePlane{
    background-image: linear-gradient(to bottom, #AAA 1px, transparent 1px), linear-gradient(to right, #AAA 1px, transparent 1px), radial-gradient(1px 1px at center, #AAA 1px, transparent 2px), radial-gradient(1px 1px at center, #AAA 1px, transparent 2px);
    background-size: 40px 60px, 120px 40px, 6px 60px, 6px 60px;
    background-position: 0px 0px, 0px 0px, 0px -10px, 0px 10px;
}