.muvTravelBlog div.carousel-heading-2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 100px;
    font-family: 'Futura Hv BT';
}


.muvTravelBlog .card-img-top {
    height: 277px;
}

.muvTravelBlog .card-body {
    color: white;
    background-color: #363c48;
    padding: 5px 20px 0px 20px;
    margin-top: -1px;
}

.muvTravelBlog .card {
    border: 0;
}

.muvTravelBlog div.card-title {
    text-align: center;
    position: absolute;
    color: white;
    font: 24px/26px "Futura Md BT";
    bottom: 45px;
    left: 3px;
    right: 8px;
    margin: 0 auto;
}

.muvTravelBlog div.p {
    text-align: right;
    margin-bottom: 5px;
    font: 18px/26px "Futura Lt BT";
}

.muvTravelBlog .blogItems {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 900px) {

    .muvTravelBlog div.carousel-heading-2 {
        margin-bottom: 30px;
        margin-top: 50px;
    }

    .muvTravelBlog div.card-title {
        font-size: 12px;
        font-weight: 50;
        max-width: 220px;
        bottom: 30px;
    }

    .muvTravelBlog div.p {
        font-size: 12px;
        font-family: 'Futura Md BT';
    }
}

.timeline-image .slick-slide {
  margin-right: 4px;
}